
import { ModelObject } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'

import { iconUrlForTheme } from '@/helpers/theme'

import List from './list.vue'

@Component({
  components: {
    List
  },
  name: 'ModeObjectSelectMenu'
})
export default class extends Vue {
  iconUrlForTheme = iconUrlForTheme

  @Prop() readonly activeObject?: ModelObject
  @Prop() readonly checkedObjects?: ModelObject[]
  @Prop() readonly objects!: ModelObject[]
  @Prop() readonly menuProps?: any

  @Ref() readonly menuRef!: any

  open () {
    this.menuRef.isActive = true
  }

  close () {
    this.menuRef.isActive = false
  }
}
