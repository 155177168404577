
import { ModelConnection, ModelObject, PermissionType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import Tabs, { ITab } from '@/components/tabs.vue'
import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ModelModule } from '@/modules/model/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../../helpers/analytics'
import { directConnections, lowerConnections } from '../../helpers/connections'
import Header from './header.vue'
import Item from './item.vue'

@Component({
  components: {
    Header,
    Item,
    Tabs
  },
  name: 'ModelObjectConnectionsList'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  modelModule = getModule(ModelModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  @Prop() readonly origin!: ModelObject
  @Prop() readonly target!: ModelObject
  @Prop() readonly permission!: PermissionType

  @Ref() readonly virtualScrollRef!: { $el: HTMLElement, onScroll: () => void }

  itemHeight = 54

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.verisonId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get expandedConnection () {
    return this.$queryValue('expanded_connection')
  }

  get expandedConnectionTab () {
    return this.$queryValue('expanded_connection_tab') as 'direct' | 'lower' | null
  }

  get directConnections () {
    return directConnections(this.origin.id, this.target.id, this.modelModule.connections)
  }

  get lowerConnections () {
    return lowerConnections(this.origin, this.target, this.modelModule.objects, this.modelModule.connections)
  }

  get tabs () {
    const tabs: ITab[] = [
      {
        id: 'direct',
        text: `${this.directConnections.length} direct connections`,
        to: {
          query: this.$setQuery({
            expanded_connection_tab: this.expandedConnectionTab ? 'direct' : undefined
          })
        }
      },
      {
        id: 'lower',
        text: `${this.lowerConnections.length} lower connections`,
        to: {
          query: this.$setQuery({
            expanded_connection_tab: 'lower'
          })
        }
      }
    ]
    return tabs
  }

  get items () {
    const directConnections = this.directConnections
    const lowerConnections = this.lowerConnections

    const connections: ModelConnection[] = []
    if (!this.expandedConnectionTab || this.expandedConnectionTab === 'direct') {
      connections.push(...directConnections)
    }
    if (this.expandedConnectionTab === 'lower') {
      connections.push(...lowerConnections)
    }

    return connections.sort((a: ModelConnection, b: ModelConnection) => {
      const connectionADiagramCount = Object.keys(a.diagrams).length
      const connectionBDiagramCount = Object.keys(b.diagrams).length

      if (connectionADiagramCount === connectionBDiagramCount) {
        return a.name.localeCompare(b.name)
      } else {
        return connectionADiagramCount - connectionBDiagramCount
      }
    })
  }

  @Watch('origin')
  onOriginChanged (origin: ModelObject, prevOrigin: ModelObject) {
    if (this.currentLandscape && origin.id !== prevOrigin.id) {
      this.replaceExpandedConnectionTab()

      analytics.modelObjectConnectionsListScreen.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }

  @Watch('target')
  onTargetChanged (target: ModelObject, prevTarget: ModelObject) {
    if (this.currentLandscape && target.id !== prevTarget.id) {
      this.replaceExpandedConnectionTab()

      analytics.modelObjectConnectionsListScreen.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }

  mounted () {
    this.replaceExpandedConnectionTab()

    if (this.currentLandscape) {
      analytics.modelObjectConnectionsListScreen.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }

  @Watch('items')
  onItemsChanged (items: ModelConnection[], prevItems: ModelConnection[]) {
    if (items.length !== prevItems.length) {
      const index = this.virtualScrollRef.$el.scrollTop / this.itemHeight
      const topItem = prevItems[index]
      if (topItem) {
        const newItemIndex = items.findIndex(o => o.id === topItem.id)
        if (newItemIndex > -1) {
          this.virtualScrollRef.$el.scrollTop = newItemIndex * this.itemHeight
        }
      }

      this.$nextTick(() => this.virtualScrollRef?.onScroll())
    }
  }

  replaceExpandedConnectionTab () {
    const hasDirectConnections = this.lowerConnections.length === 0 && this.directConnections.length > 0
    const hasLowerConnections = this.directConnections.length === 0 && this.lowerConnections.length > 0

    if (hasDirectConnections) {
      this.$replaceQuery({
        expanded_connection_tab: 'direct'
      })
    } else if (hasLowerConnections) {
      this.$replaceQuery({
        expanded_connection_tab: 'lower'
      })
    }
  }
}
